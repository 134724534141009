
<template>
  <vx-card :title="title">
    <div class="vx-row mb-5 ml-1">
      <vs-button
        color="danger"
        icon-pack="feather"
        icon="icon-skip-back"
        @click="handleBack()"
      >Back</vs-button>
    </div>
    <div>
      <div class="vx-row mb-6" style="width:70%">
        <div class="vx-col sm:w-1/4 w-full">
          <span>Sales</span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <span><b>: {{ salesName }}</b></span>
        </div>
      </div>
      <div class="vx-row mb-6" style="width:70%">
        <div class="vx-col sm:w-1/4 w-full">
          <span>Territory</span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <span><b>: {{ territory }}</b></span>
        </div>
      </div>
      <div class="vx-row mb-6" style="width:70%">
        <div class="vx-col sm:w-1/4 w-full">
          <span>Survey Date</span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <span v-if="startDate != '0001-01-01'"><b>: {{ startDate }} - {{ endDate }}</b></span>
          <span v-else><b>: -</b></span>
        </div>
      </div>
      <vs-table
        search
        stripe
        border
        description
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.total"
        :buttonSearch="true"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
      >
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </template>
        <template slot="thead">
          <vs-th sort-key="customer_name">Customer</vs-th>
          <vs-th sort-key="survey_code">Survey ID</vs-th>
          <vs-th sort-key="survey_name">Survey Name</vs-th>
          <vs-th sort-key="question">Question</vs-th>
          <vs-th sort-key="answer">Answer</vs-th>
          <vs-th sort-key="answer_time">Answer Time</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.customer_name">{{ tr.customer_name }}</vs-td>
            <vs-td :data="tr.survey_code">{{ tr.survey_code }}</vs-td>
            <vs-td :data="tr.survey_name">{{ tr.survey_name }}</vs-td>
            <vs-td :data="tr.question">{{ tr.question }}</vs-td>
            <vs-td :data="tr.answer">{{ tr.answer }}</vs-td>
            <vs-td :data="tr.answer_time">{{ tr.answer_time }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
    </div>
  </vx-card>
</template>
<script>
import moment from "moment";
export default {
  components: {},
  data() {
    return {
      baseUrl: "/api/sfa/v1/customer-survey",
      deleteId: null,
      table: this.tableDefaultState(),
      title: "Customer Survey Monitoring Detail",
      salesName: "sdd",
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 5,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      if (this.table.total > 0 && searching.length > 4) {
        this.getData();
      } else if (searching.length == 0) {
        this.getData();
      }
    },
    handleChangePage(page) {
      this.table.page = page;
      if (this.table.total > 0) {
        this.getData();
      }
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      if (this.table.total > 0) {
        this.getData();
      }
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      if (this.table.total > 0) {
        this.getData();
      }
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("Y-MM-DD");
      }
      return a;
    },
    handleBack(){
      this.$router.push("/survey/monitoring")
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/monitoring-detail", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            sales_personal_id: this.salesPersonalID,
            territory_id: this.territoryID,
            date_from: this.startDate,
            date_to: this.endDate,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
  },
  watch: {
  },
  mounted() {
    this.salesName = this.$route.query.sales;
    this.territory = this.$route.query.territory;
    this.territoryID = this.$route.query.territory_id;
    this.startDate = this.$route.query.start_date;
    this.endDate = this.$route.query.end_date;
    this.salesPersonalID = this.$route.query.id;
    this.getData();
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>